import { createLazyFileRoute } from "@tanstack/react-router";
import { BillingAccountView } from "../../../../modules/billing/billingAccount/BillingAccountView.tsx";

export const Route = createLazyFileRoute("/_main/billing/account/$id/")({
    component: Component,
});

function Component() {
    const { id } = Route.useParams();
    const baId = Number(id);
    return <BillingAccountView key={baId} baId={baId} />;
}
