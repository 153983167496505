import { mcn, type BaseProps } from "../../utils/baseProps.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { BoundCardMethod } from "./BoundMethod.ts";
import { ConversionMessage } from "./ConversionMessage.tsx";
import { PaymentFees } from "./PaymentFees.tsx";

export function AllFees({
    billingAccount,
    amount,
    primaryCardMethod,
    ...props
}: {
    billingAccount: EBillingAccount;
    amount: number;
    primaryCardMethod: BoundCardMethod | undefined;
} & BaseProps) {
    return (
        <div {...mcn("flex flex-col gap-2", props)}>
            <PaymentFees account={billingAccount} amount={amount} fees={primaryCardMethod?.method.fee} />
            <ConversionMessage
                account={billingAccount}
                amount={amount}
                fees={primaryCardMethod?.method.fee}
                card={primaryCardMethod?.card}
            />
        </div>
    );
}
