import type { BillingAccountInvoice } from "@warrenio/api-spec/spec.oats.gen";
import { link } from "../../../components/Action.tsx";
import type { BillingAccountWithType } from "../billingAccountQuery.ts";
import type { EBillingAccount } from "../billingLogic.tsx";

export function makeInvoiceLink(
    { id, type }: BillingAccountInvoice,
    { id: baId }: BillingAccountWithType | EBillingAccount,
) {
    return link({
        to: "/billing/account/$id/detail/$type/$invoiceId",
        params: { id: String(baId), type, invoiceId: String(id) },
    });
}
